<template>
	<div class="about-container">
		<div class="banner">
			<img src="@/assets/global/about/about-banner.png" alt="banner">
		</div>
		<div class="view">
			<img class="part1" src="../../assets/global/about/part1.png" />
			<img class="part1" src="../../assets/global/about/part2.png" />
			<div class="lunbo">
				<img class="lunboImg" v-if="currentIndex == 0" src="../../assets/global/about/text1.png">
				<img class="lunboImg" v-if="currentIndex == 1" src="../../assets/global/about/text2.png">
				<img class="lunboImg" v-if="currentIndex == 2" src="../../assets/global/about/text3.png">
				<img class="lunboImg" v-if="currentIndex == 3" src="../../assets/global/about/text4.png">
				<!-- <div class="page" v-if="dataList.length > 1">
				    <ul>
				      <li @click="gotoPage(prevIndex)">&lt;</li>
				      <li v-for="(item,index) in dataList" @click="gotoPage(index)" :class="{'current':currentIndex == index}">  {{index+1}}</li>
				      <li @click="gotoPage(nextIndex)">&gt;</li>
				    </ul>
				  </div> -->
			</div>
			<img class="part2" src="../../assets/global/about/part4.png" />
			<img class="part2" src="../../assets/global/about/part5.png" />
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				dataList: [
					"../../assets/global/about/text1.png",
					"../../assets/global/about/text2.png",
					"../../assets/global/about/text3.png",
					"../../assets/global/about/text4.png"
				],

				currentIndex: 0, //默认显示图片
				timer: null //定时器
			};
		},
		computed: {
			//上一张
			prevIndex() {
		 	if (this.currentIndex == 0) {
					return this.dataList.length - 1;
				} else {
					return this.currentIndex - 1;
				}
			},
			//下一张
			nextIndex() {
				if (this.currentIndex == this.dataList.length - 1) {
					return 0;
				} else {
					return this.currentIndex + 1;
				}
			}
		},
		mounted() {
			this.runInv();
		},
		methods: {
			clearTimer() {

				clearInterval(this.timer)
			},
			gotoPage(index) {
				this.currentIndex = index;
			},
			runInv() {

				this.timer = setInterval(() => {
					this.gotoPage(this.nextIndex)
				}, 2000)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.about-container {

		.banner {
			>img {
				width: 100%;
			}
		}

		.view {
			width: 100%;

			.part1 {
				width: 100%;
			}

			.part2 {
				width: 100%;
				margin-top: 48px;
			}

			.lunbo {
				height: 320px;
				width: 100%;
				display: flex;
				background-color: #00AFC7;
				flex-direction: column;
				background-image: url(../../assets/global/about/bg2.png);
				background-repeat: no-repeat;
				// background-attachment:fixed;
				background-size: cover;
				.lunboImg {
					margin: auto;
					width: 311px;
					height: 152px;
				}

				.page {
					display: flex;
					flex-direction: row;
					background: rgba(0, 0, 0, .5);
					margin-top: auto;
					width: 100%;

					ul {
						margin: auto;
					}

					ul li {
						list-style: none;
						float: left;
						width: 30px;
						height: 40px;
						line-height: 40px;
						text-align: center;
						cursor: pointer;
						color: rgba(255, 255, 255, .8);
						font-size: 14px;
					}

				}
			}
		}
	}
</style>
